import { FC, useCallback } from 'react';
import classes from './RightToolbar.module.scss';
import { ZoomIn, ZoomOut } from 'components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { mapActions } from 'store';
import { OutlineContainer } from 'components/containers';
import { AttributesTable } from './attributesTable/AttributesTable';
import { RootState } from 'store';

const showZoom = window.location.search === '?showZoom';

const RightToolbar: FC = () => {
    const { currentZoom } = useSelector((x: RootState) => x.map);
    const dispatch = useDispatch();

    const zoomHandler = useCallback(
        (offset: number) => {
            dispatch(mapActions.zoom(offset));
        },
        [dispatch]
    );

    return (
        <div className={classes.container}>
            <OutlineContainer className={classes.iconButton} onClick={() => zoomHandler(1)}>
                <ZoomIn />
            </OutlineContainer>
            <OutlineContainer className={classes.iconButton} onClick={() => zoomHandler(-1)}>
                <ZoomOut />
            </OutlineContainer>
            {showZoom && (
                <OutlineContainer className={classes.iconButton} onClick={() => {}}>
                    {currentZoom.toFixed(2)}
                </OutlineContainer>
            )}
            <AttributesTable />
        </div>
    );
};

export default RightToolbar;

