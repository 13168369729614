import { Dispatch, SetStateAction } from 'react';
import { Snap, Modify } from 'ol/interaction';

export enum LeftToolBarTypes {
    none = 'none',
    find = 'find',
    coord = 'coord',
    layer = 'layer',
    download = 'download'
}

export type SetState<S> = Dispatch<SetStateAction<S>>;

export type Nullable<S> = S | null;

export interface Interactions {
    snap: Snap;
    modify: Modify;
}

export interface Polygon {
    type: 'Polygon';
    coordinates: [number, number][][];
}

export interface Point {
    type: 'Point';
    coordinates: [number, number];
}

export interface Area {
    id: number;
    nome: string;
    bbox: Polygon;
}

export interface Project {
    id: string;
    nome: string;
    comune: string;
    fwa: boolean;
    diretto?: boolean;
    bbox: Polygon;
    project: string;
}

export interface Srb {
    id: number;
    codice: string;
    coords: Point;
}

export type AreaOption = Omit<Area, 'nome'> & { label: string };
export type ProjectOption = Omit<Project, 'nome' | 'comune'> & { label: string; type?: 'fwa' | 'diretto' | 'concessione' };
export type SrbOption = Omit<Srb, 'codice'> & { label: string };

export type ModelKey = 'diretto' | 'concessione' | 'fwa';
type FiberEntity = 'tratte' | 'ui' | 'roe';
type FwaEntity = 'srb' | 'ui' | 'area_copertura';
export type ResultEntity = FiberEntity | FwaEntity;
export type ResultKey = `${ModelKey}_${ResultEntity}`;
export type Result = { rowCount?: number; extraction: Record<string, string | number | null>[] };

export interface ExtractionResult {
    diretto: Result;
    concessione: Result;
    fwa: Result;
}

export interface Option {
    id: number | string;
    label: string;
}

export interface OptionWithCoordinates extends Option {
    value: [number, number];
}

export interface AddressFeature {
    geometry: {
        coordinates: [number, number];
        type: string;
    };
    type: string;
    properties: {
        osm_type: string;
        osm_id: number;
        country: string;
        osm_key: string;
        city: string;
        countrycode: string;
        osm_value: string;
        postcode: string;
        name: string;
        county: string;
        state: string;
        type: string;
        street: string;
        housenumber: string;
    };
}

export interface AddressData {
    features: AddressFeature[];
    type: string;
}
