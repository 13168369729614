import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { bboxPolygon, union, multiPolygon } from '@turf/turf';
import { SelectionUtils, municipalitiesMaxZoom, hoveredFeatureId, hoveredLayer, map } from 'helpers';
import { MapboxVectorLayer } from 'ol-mapbox-style';
import { boundingExtent } from 'ol/extent';
import Feature from 'ol/Feature';
import { Geometry, Point } from 'ol/geom';
import Draw from 'ol/interaction/Draw';
import Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import OlMap from 'ol/Map';
import LayerRenderer from 'ol/renderer/Layer';
import { Source } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import { downloadProjectActions, mapActions, popupActions, searchActions } from 'store';
import { GeoJSON } from 'ol/format';

const style = new Style({
    fill: new Fill({
        color: 'rgba(0, 134, 255, 0.3)'
    }),
    stroke: new Stroke({
        color: 'rgba(0, 134, 255, 0.8)',
        width: 5
    })
});

export const selectionLayer = new VectorLayer({
    source: undefined,
    style,
    properties: { id: 'selection', overlay: true }
});

function layerFilter(arg0: Layer<Source, LayerRenderer<any>>, include: string[] = [], exclude: string[] = []) {
    if (include.length && !include.includes(arg0.getProperties().title)) return false;
    if (exclude.length && exclude.includes(arg0.getProperties().title)) return false;
    return !(arg0 instanceof MapboxVectorLayer) && !arg0.getProperties().overlay;
}

export const registerSelectionHandler = (dispatch: Dispatch<AnyAction>) => {
    map.on('movestart', () => {
        if (!selectionLayer.get('keepSelection')) {
            selectionLayer.setSource(null);
            selectionLayer.changed();
        }
        dispatch(popupActions.setOpen(false));
    });

    map.on('singleclick', event => {
        const olMap = map as OlMap;
        const istatLayers = ['istat_comuni', 'istat_province', 'istat_regioni'];
        const isDrawing = !!olMap
            .getInteractions()
            .getArray()
            .find(interaction => interaction instanceof Draw);
        if (isDrawing) return;

        dispatch(popupActions.setOpen(false));

        const zoom = map.getView().getZoom()!;

        const clickedFeatures = olMap.getFeaturesAtPixel(event.pixel, {
            hitTolerance: 2,
            layerFilter: arg0 => layerFilter(arg0)
        });

        if (clickedFeatures.length === 0) {
            selectionLayer.setSource(null);
            selectionLayer.changed();
            return;
        }

        const selectedFeature = clickedFeatures[0] as Feature<Geometry>;
        const selectedLayer = olMap
            .getAllLayers()
            .find(layer => layer.getProperties().name?.endsWith(selectedFeature.getProperties().layer))!;

        if (selectedLayer.getProperties().name === 'fwa:fwa_area_copertura') {
            const allSelectedFeatures = (selectedLayer as VectorLayer<VectorSource<Geometry>>)
                .getSource()
                ?.getFeaturesInExtent(olMap.getView().getViewStateAndExtent().extent)!;

            const allSelectedFilterFeatures = allSelectedFeatures.filter(
                value => value.getProperties().id === selectedFeature.getProperties().id
            );

            if (allSelectedFilterFeatures.length > 0 && allSelectedFilterFeatures.length !== 1) {
                let mergedSelectedFeatures;
                let selectedMultipolygon = multiPolygon(selectedFeature.getProperties().geometry.getCoordinates());
                for (let index = 0; index < allSelectedFilterFeatures.length; index++) {
                    const selectedFilterFeature = multiPolygon(
                        allSelectedFilterFeatures[index].getProperties().geometry.getCoordinates()
                    );
                    if (!mergedSelectedFeatures) mergedSelectedFeatures = union(selectedMultipolygon, selectedFilterFeature);
                    else mergedSelectedFeatures = union(mergedSelectedFeatures, selectedFilterFeature);
                }
                if (mergedSelectedFeatures) {
                    const transformedMergedFeatures = new GeoJSON({ dataProjection: 'EPSG:3857' }).readFeature(
                        JSON.stringify(mergedSelectedFeatures)
                    );

                    if (transformedMergedFeatures) {
                        selectedFeature.setGeometry(transformedMergedFeatures.getGeometry());
                    }
                }
            }
        }

        const featureProps = selectedFeature.getProperties();
        const layerTitle = selectedLayer.getProperties().title;

        if (istatLayers.includes(layerTitle)) {
            if (layerTitle === 'istat_regioni') {
                dispatch(downloadProjectActions.setState({ key: 'regionId', value: featureProps.id }));
            }
            if (['istat_province', 'istat_comuni'].includes(layerTitle)) {
                dispatch(downloadProjectActions.setState({ key: 'regionId', value: featureProps.regione_id }));
            }

            if (zoom < municipalitiesMaxZoom) {
                const mapExtent = olMap.getView().calculateExtent(olMap.getSize());
                const layerSource = selectedLayer.getSource() as VectorSource<Geometry>;
                const featuresCollection = layerSource
                    ?.getFeaturesInExtent(mapExtent)
                    .filter(feature => feature.getProperties().id === featureProps.id);
                const extentArray = featuresCollection.map(feature => feature.getGeometry()!.getExtent());
                const collectionExtent = boundingExtent(
                    extentArray.flatMap(v => [
                        [v[0], v[1]],
                        [v[2], v[3]]
                    ])
                ) as any;

                const polygon = bboxPolygon(collectionExtent);

                return dispatch(mapActions.zoomOnPolygon(polygon as any));
            }
        } else {
            const layerId = selectedLayer.getProperties().name;
            const source = new VectorSource({ features: [new Feature(selectedFeature.getGeometry())] });
            selectionLayer.setSource(source);
            selectionLayer.setZIndex(selectedLayer.getZIndex() ?? 0 + 1);
            selectionLayer.changed();

            const modelName = layerId.split(':')[0];

            let info: Record<string, string | null> = {};

            for (let key in featureProps)
                if (!SelectionUtils.reservedKeys.includes(key))
                    info[SelectionUtils.transformKey(key)] = SelectionUtils.transformValue(key, featureProps[key]);

            const title = SelectionUtils.getFeatureTitle(featureProps.layer, featureProps);

            const infoStyle = document.getElementById('info')!.style;
            const navbarHeigt = document.getElementById('navbar')!.offsetHeight;
            infoStyle.left = event.pixel[0] + 'px';
            infoStyle.top = event.pixel[1] + navbarHeigt + 'px';

            dispatch(popupActions.setTitle(title));
            dispatch(popupActions.setModel(modelName));
            dispatch(popupActions.setInfo(info));
            dispatch(popupActions.setProperties(featureProps));
            dispatch(popupActions.setCoordinates((selectedFeature.getGeometry() as Point).getCoordinates()));
            dispatch(popupActions.setOpen(true));
        }
    });
};

const overlaySource = new VectorSource();

export const featureOverlay = new VectorLayer({
    source: overlaySource,
    zIndex: 1000,
    style: {
        'stroke-color': 'rgba(255, 255, 255, 0.7)',
        'stroke-width': 2,
        'fill-color': 'rgba(65, 34, 255, 0.7)'
    },
    properties: { id: 'featureOverlay', overlay: true }
});

export const registerHoverHandler = (dispatch: Dispatch<AnyAction>) => {
    map.on('pointermove', async event => {
        const olMap = map as OlMap;
        const isDrawing = !!olMap
            .getInteractions()
            .getArray()
            .find(interaction => interaction instanceof Draw);
        const zoom = map.getView().getZoom()!;
        const layers = olMap.getAllLayers();

        const regionLayer = layers.find(layer => layer.getProperties().title === 'istat_regioni') as VectorLayer<
            VectorSource<Geometry>
        >;

        const provinceLayer = layers.find(layer => layer.getProperties().title === 'istat_province') as VectorLayer<
            VectorSource<Geometry>
        >;

        const municipalityLayer = layers.find(layer => layer.getProperties().title === 'istat_comuni') as VectorLayer<
            VectorSource<Geometry>
        >;

        const provinceLabelLayer = layers.find(layer => layer.getProperties().id === 'province-label-layer') as VectorLayer<
            VectorSource<Geometry>
        >;

        const municipalityLabelLayer = layers.find(
            layer => layer.getProperties().id === 'municipality-label-layer'
        ) as VectorLayer<VectorSource<Geometry>>;

        const drawPolygonLayer = layers.find(layer => layer.getProperties().id === 'draw') as
            | VectorLayer<VectorSource<Geometry>>
            | undefined;
        const isPolygonDrawn = (drawPolygonLayer?.getSource()?.getFeatures().length || 0) > 0;

        if (isDrawing || isPolygonDrawn || zoom > 14) {
            const layer = layers.find(
                layer => layer.getProperties().name && (layer.getProperties().name as string) === hoveredLayer.value
            ) as VectorLayer<VectorSource<Geometry>>;
            const wasHovered = hoveredLayer.value.length;
            hoveredLayer.value = '';
            hoveredFeatureId.value = '';
            layer?.changed();
            provinceLabelLayer?.changed();
            municipalityLabelLayer?.changed();

            if (wasHovered) {
                regionLayer?.changed();
                provinceLayer?.changed();
                municipalityLayer?.changed();
            }
            return;
        }

        const features = map.getFeaturesAtPixel(event.pixel, {
            hitTolerance: 1,
            layerFilter(arg0) {
                return !(arg0 instanceof MapboxVectorLayer) && !arg0.getProperties().overlay;
            }
        });

        if (features.length) {
            const layerName = features[0].getProperties().layer;
            const layer = layers.find(
                layer => layer.getProperties().name && (layer.getProperties().name as string).endsWith(layerName)
            ) as VectorLayer<VectorSource<Geometry>>;
            hoveredLayer.value = layerName;
            hoveredFeatureId.value = features[0].getProperties().id;
            layer?.changed();
            municipalityLabelLayer?.changed();
            provinceLabelLayer?.changed();
        } else {
            const layer = layers.find(
                layer => layer.getProperties().name && layer.getProperties().name === hoveredLayer.value
            ) as VectorLayer<VectorSource<Geometry>>;
            hoveredLayer.value = '';
            hoveredFeatureId.value = '';
            layer?.changed();
            municipalityLabelLayer?.changed();
            provinceLabelLayer?.changed();
        }
    });

    map.on('moveend', _event => {
        const olMap = map as OlMap;
        const zoom = olMap.getView().getZoom()!;
        if (hoveredLayer.value && (zoom <= 8.3 || zoom >= 12)) {
            const layers = olMap.getAllLayers();
            hoveredLayer.value = '';
            hoveredFeatureId.value = '';
            const provinceLayer = layers.find(layer => layer.getProperties().title === 'istat_province') as VectorLayer<
                VectorSource<Geometry>
            >;
            const provinceLabelLayer = layers.find(layer => layer.getProperties().id === 'province-label-layer') as VectorLayer<
                VectorSource<Geometry>
            >;
            const municipalityLabelLayer = layers.find(
                layer => layer.getProperties().id === 'municipality-label-layer'
            ) as VectorLayer<VectorSource<Geometry>>;

            municipalityLabelLayer?.changed();
            provinceLabelLayer?.changed();
            provinceLayer?.changed();
            const source = provinceLayer?.getSource() as any;
            source?.tileCache?.expireCache({});
            source?.tileCache?.clear();
            source?.refresh();
        }
    });
};
