import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
    loading: boolean;
    open: boolean;
    title: string;
    model: string;
    info: Record<string, string | null>;
    properties: Record<string, string | null>;
    coordinates?: number[];
}

export interface PopupState extends State {}

const name = 'popupState';
const initialState: State = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const popupActions = { ...slice.actions };
export const popupReducer = slice.reducer;

function createInitialState() {
    return {
        open: false,
        title: '',
        model: '',
        info: {},
        properties: {}
    } as State;
}

function createReducers() {
    return { setLoading, setOpen, setTitle, setModel, setInfo, setProperties, setCoordinates };

    function setLoading(state: PopupState, action: PayloadAction<typeof state.open>) {
        state.loading = action.payload;
    }
    function setOpen(state: PopupState, action: PayloadAction<typeof state.open>) {
        state.open = action.payload;
    }
    function setTitle(state: PopupState, action: PayloadAction<typeof state.title>) {
        state.title = action.payload;
    }
    function setModel(state: PopupState, action: PayloadAction<typeof state.model>) {
        state.model = action.payload;
    }
    function setInfo(state: PopupState, action: PayloadAction<typeof state.info>) {
        state.info = action.payload;
    }
    function setProperties(state: PopupState, action: PayloadAction<typeof state.properties>) {
        state.properties = action.payload;
    }
    function setCoordinates(state: PopupState, action: PayloadAction<typeof state.coordinates>) {
        state.coordinates = action.payload;
    }
}
