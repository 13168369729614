import { OutlineContainer } from 'components/containers';
import { LocationTarget } from 'components/icons';
import { Arrow } from 'components/icons/Arrow';
import { LeftToolBarTypes } from 'helpers';
import { FC } from 'react';
import classes from './CoordFinder.module.scss';
import CoordFinderBody from './coordFinderBody/CoordFinderBody';

type CoordFinderProps = FC<{
    activeTab: LeftToolBarTypes;
    setActiveTab: (tab: LeftToolBarTypes) => void;
}>;

const CoordFinder: CoordFinderProps = ({ activeTab, setActiveTab }) => {
    return (
        <OutlineContainer className={classes.round}>
            <div className={classes.header} onClick={() => setActiveTab(LeftToolBarTypes.coord)}>
                <div className={classes.iconText}>
                    <LocationTarget />
                    <b>Portami a</b>
                </div>
                <Arrow direction={activeTab === LeftToolBarTypes.coord ? 'up' : 'down'} />
            </div>

            <div>{activeTab === LeftToolBarTypes.coord && <CoordFinderBody />}</div>
        </OutlineContainer>
    );
};

export default CoordFinder;

