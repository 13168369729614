import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
    coordinateString: string;
}

export interface CoordsPopupState extends State {}

const name = 'coordsPopupState';
const initialState: State = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const coordsPopupActions = { ...slice.actions };
export const coordsPopupReducer = slice.reducer;

function createInitialState() {
    return {
        coordinateString: ''
    } as State;
}

function createReducers() {
    return { setCoordinateString };

    function setCoordinateString(state: CoordsPopupState, action: PayloadAction<typeof state.coordinateString>) {
        state.coordinateString = action.payload;
    }
}

