import { Spinner } from 'components/icons/Spinner';
import { FC } from 'react';
import classes from './LoadingPage.module.scss';

const LoadingPage: FC = () => {
    return (
        <div className={classes.container}>
            <span className={classes.spinner}>
                <Spinner />
            </span>
        </div>
    );
};

export default LoadingPage;

