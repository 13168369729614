import AutoCompleteInput from 'components/forms/AutoCompleteInput';
import { AddressFeature, Option, OptionWithCoordinates } from 'helpers';
import useDebouncedValue from 'hook/useDebouncedValue';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapActions, MapState, RootState, useLazyGetAddressesQuery } from 'store';

type AddressSearchFieldProps = FC<{
    handleSearchByCoord: () => void;
    handleClearCoord: () => void;
}>;

const AddressSearchField: AddressSearchFieldProps = ({ handleSearchByCoord, handleClearCoord }) => {
    const { locationSearchTab } = useSelector((state: RootState) => state.tab);
    const [searchWord, setSearchWord] = useState('');
    const { markerInfo } = useSelector((x: RootState) => x.map as MapState);
    const dispatch = useDispatch();
    const [triggerGetAddresses, { data }] = useLazyGetAddressesQuery();
    const debouncedSearchTerm = useDebouncedValue(searchWord, 500);

    const options: Option[] = useMemo(() => {
        if (debouncedSearchTerm === '' || !data?.features) return [];

        const idSet = new Set();
        const filteredResults: AddressFeature[] = [];

        for (let i = 0; i < data.features.length; i++) {
            const feature = data.features[i];
            const id = feature.properties.osm_id;
            if (!idSet.has(id)) filteredResults.push(feature);
            idSet.add(id);
        }

        return filteredResults
            .map(({ geometry, properties }) => {
                const { coordinates } = geometry;
                const { osm_id, housenumber, street, city, name, state, country, postcode } = properties;
                const label = housenumber
                    ? `${street}, ${housenumber}, ${city}${postcode ? ` ${postcode}` : ''}`
                    : `${name}, ${city || state || country}${postcode ? ` ${postcode}` : ''}`;

                return {
                    value: [coordinates[1], coordinates[0]],
                    id: osm_id,
                    label
                };
            })
            .reduce((prev, curr) => {
                if (!prev.find(element => element.label === curr.label)) prev.push(curr);
                return prev;
            }, [] as Option[]);
    }, [data, debouncedSearchTerm]);

    const handleOnChange = useCallback(
        (opt: OptionWithCoordinates) => {
            dispatch(
                mapActions.setMarkerInfo({
                    coordinateString: opt?.value.join(','),
                    type: locationSearchTab
                })
            );
            dispatch(mapActions.setMarkerInfo({ selectedOption: opt }));
        },
        [dispatch, locationSearchTab]
    );

    const handleInputChange = useCallback(
        (value: string) => {
            setSearchWord(value);
            if (value === '') handleClearCoord();
        },
        [handleClearCoord]
    );

    const handleClear = useCallback(() => {
        handleClearCoord();
        setSearchWord('');
    }, [handleClearCoord]);

    const getAddressesBySearchWord = useCallback(
        async (word: string) => {
            await triggerGetAddresses(word).catch(e => console.error(e));
        },
        [triggerGetAddresses]
    );

    useEffect(() => {
        if (debouncedSearchTerm || debouncedSearchTerm === '') getAddressesBySearchWord(debouncedSearchTerm);
    }, [getAddressesBySearchWord, debouncedSearchTerm]);

    useEffect(() => {
        if (markerInfo.coordinateString && markerInfo.type === 'address') handleSearchByCoord();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markerInfo.coordinateString]);

    return (
        <AutoCompleteInput
            id="address"
            label="Cerca Indirizzo"
            placeholder="(es. Via Risorgimento, 75, Milano)"
            options={options}
            getOptionLabel={(option: any) => option.label}
            onChange={(_ev, opt) => handleOnChange(opt as OptionWithCoordinates)}
            filterOptions={options => options}
            onInputChange={(_e, value, _reason) => handleInputChange(value)}
            handleClear={handleClear}
            value={markerInfo.selectedOption}
        />
    );
};

export default AddressSearchField;
