import { FC } from 'react';

export const GpsMarker: FC = () => {
    return (
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.54181 19.7686L8.54617 19.7641L8.56207 19.7474C8.5758 19.733 8.59574 19.7121 8.62147 19.6848C8.67292 19.6302 8.74756 19.5504 8.84207 19.4476C9.03104 19.2422 9.29972 18.9446 9.62155 18.5731C10.2645 17.8309 11.1232 16.7895 11.9837 15.5945C12.8424 14.4018 13.7133 13.0417 14.372 11.6632C15.0256 10.2955 15.5 8.84925 15.5 7.5C15.5 4.90326 14.4587 3.00405 12.9713 1.76709C11.5053 0.548005 9.64212 0 8 0C6.35788 0 4.49471 0.548005 3.02874 1.76709C1.54127 3.00405 0.5 4.90326 0.5 7.5C0.5 8.84925 0.974394 10.2955 1.62799 11.6632C2.28674 13.0417 3.15759 14.4018 4.01635 15.5945C4.87676 16.7895 5.7355 17.8309 6.37845 18.5731C6.70028 18.9446 6.96896 19.2422 7.15793 19.4476C7.25244 19.5504 7.32708 19.6302 7.37853 19.6848L7.38344 19.69L7.41372 19.722L7.43793 19.7474L7.45383 19.7641L7.45987 19.7703L7.46012 19.7706C7.60147 19.9172 7.79636 20 8 20C8.20364 20 8.39853 19.9172 8.53988 19.7706L8.54181 19.7686ZM10.5 7.25C10.5 8.63071 9.38071 9.75 8 9.75C6.61929 9.75 5.5 8.63071 5.5 7.25C5.5 5.86929 6.61929 4.75 8 4.75C9.38071 4.75 10.5 5.86929 10.5 7.25Z"
                fill="#5F686A"
            />
        </svg>
    );
};

