import LoadingPage from 'components/loadingPage/LoadingPage';
import {
    checkMapboxTokenValidity,
    createVectorMapSource,
    featureOverlay,
    getWorkspaceLayers,
    registerHoverHandler,
    registerSelectionHandler,
    registerZoomHandler,
    statsMaxZoom
} from 'helpers';
import { italyLayer } from 'helpers/layers/italyLayer';
import { getMapboxLayer } from 'helpers/layers/mapbox';
import { createMunicipalityLabelLayer } from 'helpers/layers/municipalityLabelLayer';
import { createProvinceLabelLayer } from 'helpers/layers/provinceLabelLayer';
import { createStatsLayers } from 'helpers/layers/statsLayer';
import { FeatureType, styles } from 'helpers/layers/styles';
import LayerGroup from 'ol/layer/Group';
import VectorTileLayer from 'ol/layer/VectorTile';
import { createContext, FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    mapActions,
    MapState,
    RootState,
    useGetMunicipalitiesCollectionQuery,
    useGetProvinceCollectionQuery,
    useGetRegionStatsQuery,
    useGetTokenAndStyleQuery
} from 'store';

export const MapContext = createContext({});

const featureLookupTable: Record<string, FeatureType> = {
    _area_copertura: 'MultiPolygon',
    _ui: 'Point',
    _pozzetti: 'Point',
    _pac_pal: 'Point',
    _tratte: 'MultiLineString',
    _srb: 'Point',
    _roe: 'Point',
    _nodi_rete: 'Point',
    _nodi_operatore: 'Point',
    _giunti: 'Point',
    _regioni: 'MultiPolygon',
    _province: 'MultiPolygon',
    _comuni: 'MultiPolygon',
    _pcn: 'Point',
    _cno: 'Point'
};

export const MapProvider: FC<PropsWithChildren> = ({ children }) => {
    const { data: regionStats } = useGetRegionStatsQuery();
    const { data: provinceCollection } = useGetProvinceCollectionQuery();
    const { data: municipalityCollection } = useGetMunicipalitiesCollectionQuery();
    const { data: mapboxTokenStyle } = useGetTokenAndStyleQuery();
    const { mapStyles } = useSelector((x: RootState) => x.map as MapState);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();

    const addLayersToMap = useCallback(
        async (id: string, minZoom = statsMaxZoom) => {
            const res = await getWorkspaceLayers(id);
            const layers = res.map(v => {
                let styleType: FeatureType;
                let style;
                for (let key in featureLookupTable)
                    if (v.name.endsWith(key)) {
                        styleType = featureLookupTable[key as keyof typeof featureLookupTable];
                        style = (styleType as any) ? styles[styleType] : undefined;
                    }

                return new VectorTileLayer({
                    declutter: true,
                    renderMode: 'vector',
                    source: createVectorMapSource(v.name),
                    minZoom: v.minZoom,
                    maxZoom: v.maxZoom,
                    zIndex: v.zIndex,
                    properties: { ...v, groupId: id },
                    style
                });
            });
            const group = new LayerGroup({ layers, minZoom, properties: { id } });
            dispatch(mapActions.addLayer(group));
        },
        [dispatch]
    );

    useEffect(() => {
        addLayersToMap('webgis', 0);
        addLayersToMap('concessione');
        addLayersToMap('diretto');
        addLayersToMap('fwa');
        registerZoomHandler(dispatch);
        registerSelectionHandler(dispatch);
        registerHoverHandler(dispatch);
    }, [addLayersToMap, dispatch]);

    useEffect(() => {
        if (!mapboxTokenStyle || mapStyles.find(style => style.value === 'mapbox')) {
            setTimeout(() => setIsLoading(false), 750);
            return;
        }
        checkMapboxTokenValidity(mapboxTokenStyle.token).then(isValid => {
            if (isValid) {
                dispatch(mapActions.addLayer(getMapboxLayer(mapboxTokenStyle.token!, mapboxTokenStyle.style!)));
                dispatch(mapActions.setMapStyles([{ value: 'mapbox', label: 'MapBox' }, ...mapStyles]));
                dispatch(mapActions.replaceMap('mapbox'));
                setTimeout(() => setIsLoading(false), 750);
            }
        });
    }, [dispatch, mapStyles, mapboxTokenStyle]);

    useEffect(() => {
        if (!regionStats) return;
        const layers = createStatsLayers(regionStats);
        layers.forEach(layer => dispatch(mapActions.addLayer(layer)));
        dispatch(mapActions.addLayer(featureOverlay));
        dispatch(mapActions.addLayer(italyLayer));
    }, [dispatch, regionStats]);

    useEffect(() => {
        if (!provinceCollection) return;
        const layer = createProvinceLabelLayer(provinceCollection);
        dispatch(mapActions.addLayer(layer));
    }, [dispatch, provinceCollection]);

    useEffect(() => {
        if (!municipalityCollection) return;
        const layer = createMunicipalityLabelLayer(municipalityCollection);
        dispatch(mapActions.addLayer(layer));
    }, [dispatch, municipalityCollection]);

    return (
        <MapContext.Provider value={{}}>
            {isLoading && <LoadingPage />}
            {children}
        </MapContext.Provider>
    );
};
