import { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapState, RootState, mapActions } from 'store';
import { InfoPopper } from './infoPopper/InfoPopper';
import classes from './Map.module.scss';
import CoordsPopup from './coordsPopup/CoordsPopup';

const Map: FC = () => {
    const { map, loading } = useSelector((x: RootState) => x.map as MapState);
    const { loading: loadingCursor } = useSelector((x: RootState) => x.popup);
    const mapRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        map.setTarget(mapRef.current ?? undefined);
        dispatch(mapActions.initMapLayers());
    }, [dispatch, map]);

    return (
        <div
            ref={mapRef}
            className={`${classes.map} ${loading ? classes.spinner : ''} ${loadingCursor ? classes.loadingCursor : ''}`}
        >
            <CoordsPopup />
            <InfoPopper />
        </div>
    );
};

export default Map;

