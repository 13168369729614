import { FC } from 'react';

export const Pozzetto: FC = () => {
    return (
        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0803 0.22998H0.670313C0.480313 0.22998 0.320312 0.38998 0.320312 0.57998V7.16998C0.320312 7.35998 0.480313 7.51998 0.670313 7.51998H10.0903C10.2803 7.51998 10.4403 7.35998 10.4403 7.16998V0.57998C10.4403 0.38998 10.2803 0.22998 10.0903 0.22998H10.0803Z"
                fill="#EAF4F7"
            />
            <path
                d="M10.38 0H0.38C0.17 0 0 0.17 0 0.38V7.38C0 7.59 0.17 7.76 0.38 7.76H10.38C10.59 7.76 10.76 7.59 10.76 7.38V0.38C10.76 0.17 10.59 0 10.38 0ZM10 6.65L1.56 0.75H10V6.65ZM0.75 1.1L9.19 7H0.75V1.1Z"
                fill="#353839"
            />
        </svg>
    );
};

