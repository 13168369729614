import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { OutlineContainer } from 'components/containers';
import { Arrow, Close, Download } from 'components/icons';
import { LeftToolBarTypes } from 'helpers';
import { FC, useCallback } from 'react';
import classes from './DownloadProject.module.scss';
import DownloadProjectBody from './downloadProjectBody/DownloadProjectBody';
import { useDispatch, useSelector } from 'react-redux';
import { downloadProjectActions, RootState } from 'store';

const errorModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    borderRadius: '0.5rem',
    border: 'none',
    boxShadow: 24,
    p: 3
};

type DownloadProjectProps = FC<{
    activeTab: LeftToolBarTypes;
    setActiveTab: (tab: LeftToolBarTypes) => void;
}>;

const DownloadProject: DownloadProjectProps = ({ activeTab, setActiveTab }) => {
    const { showErrorModal, error } = useSelector((root: RootState) => root.downloadProject);
    const dispatch = useDispatch();

    const closeModal = useCallback(
        () => dispatch(downloadProjectActions.setState({ key: 'showErrorModal', value: false })),
        [dispatch]
    );

    return (
        <OutlineContainer className={classes.round}>
            <div className={classes.header} onClick={() => setActiveTab(LeftToolBarTypes.download)}>
                <div className={classes.iconText}>
                    <Download />
                    <b>Download</b>
                </div>
                <Arrow direction={activeTab === LeftToolBarTypes.download ? 'up' : 'down'} />
            </div>
            {activeTab === LeftToolBarTypes.download && <DownloadProjectBody />}
            <Modal open={showErrorModal} onClose={closeModal}>
                <Box sx={errorModalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Errore
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {error ?? 'Download non riuscito!'}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: 'absolute',
                            right: -1,
                            top: -1
                        }}
                    >
                        <div className={classes.close}>
                            <Close />
                        </div>
                    </IconButton>
                </Box>
            </Modal>
        </OutlineContainer>
    );
};

export default DownloadProject;
