import { Arrow, Checked } from 'components/icons';
import { getWorkspaceLayers } from 'helpers';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapState, RootState, mapActions } from 'store';
import classes from './TileSelector.module.scss';
import colors from 'styles/modules/colors.module.scss';

type TileSelectorProps = { isMobile?: boolean };

const TileSelector: FC<TileSelectorProps> = ({ isMobile }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { mapStyle, mapStyles } = useSelector((x: RootState) => x.map as MapState);
    const dispatch = useDispatch();

    const openHandler = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (isMobile) return;
        getWorkspaceLayers('maps').then(value => {
            const styles = value.map(v => {
                return { value: v.name, label: v.title };
            });
            dispatch(mapActions.setMapStyles([...styles, ...mapStyles]));
            if (styles.length) dispatch(mapActions.replaceMap(styles[0].value));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isMobile]);

    const changeMapHandler = useCallback(
        (value: string) => {
            dispatch(mapActions.replaceMap(value));
            setIsOpen(false);
        },
        [dispatch, setIsOpen]
    );

    const options = useMemo(
        () =>
            mapStyles.map(style => (
                <div
                    key={style.value}
                    className={`${style.value === mapStyle ? classes.selected : classes.notSelected}`}
                    onClick={() => changeMapHandler(style.value)}
                >
                    {style.label} {isMobile && style.value === mapStyle ? <Checked fill={colors.p600} /> : <></>}
                </div>
            )),
        [changeMapHandler, mapStyle, mapStyles, isMobile]
    );
    const selectedMap = useMemo(() => mapStyles.find(style => style.value === mapStyle)?.label, [mapStyle, mapStyles]);

    return (
        <>
            <div className={classes.container}>
                {isOpen && (
                    <>
                        <b className={classes.options}>{options}</b>
                        <hr />
                    </>
                )}
                <div className={classes.selection} onClick={openHandler}>
                    <b>{selectedMap}</b>
                    <Arrow direction={isOpen ? 'up' : 'down'} />
                </div>
            </div>
            <div className={classes.mobileContainer}>
                <b className={classes.options}>{options}</b>
            </div>
        </>
    );
};

export default memo(TileSelector);

