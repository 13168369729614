import { RootState, downloadProjectActions, mapActions, searchActions, useGetRegionsQuery } from 'store';
import { AreaOption } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useDownloadOptions = () => {
    const dispatch = useDispatch();
    const { regionId } = useSelector((x: RootState) => x.downloadProject);
    const { data: regions, isFetching: regionsLoading } = useGetRegionsQuery();

    const regionOptions: AreaOption[] = useMemo(
        () =>
            regions
                ? regions.map(region => {
                      return { id: region.id, label: region.nome, bbox: region.bbox };
                  })
                : [],
        [regions]
    );
    const regionOption: AreaOption | undefined = useMemo(
        () => regionOptions.find(option => option.id === regionId) ?? { id: -1, label: '', bbox: undefined as any },
        [regionOptions, regionId]
    );

    const onRegionChangeHandler = (value: AreaOption) => {
        if (value.bbox) dispatch(mapActions.zoomOnPolygon(value.bbox));
        dispatch(downloadProjectActions.setState({ key: 'regionId', value: value.id }));
    };

    return {
        regionOptions,
        regionOption,
        regionsLoading,
        onRegionChangeHandler
    };
};
