import { FC } from 'react';

export const GpsMarkerOnMap: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M7.5 14.7955L3.75 16.5L12 20.25L20.25 16.5L16.5 14.7954M13.5 8C13.5 8.82843 12.8284 9.5 12 9.5C11.1716 9.5 10.5 8.82843 10.5 8C10.5 7.17157 11.1716 6.5 12 6.5C12.8284 6.5 13.5 7.17157 13.5 8ZM16.5 8.21959C16.5 11.3649 12 16 12 16C12 16 7.5 11.3649 7.5 8.21959C7.5 5.07432 10.0147 3.75 12 3.75C13.9853 3.75 16.5 5.07432 16.5 8.21959Z"
                stroke="#353839"
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
        </svg>
    );
};
