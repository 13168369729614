import { baseURL, epsg3857TileInfo, getTileInfo, vectorTileLoadFunction } from 'helpers';
import TileWMS from 'ol/source/TileWMS';
import TileGrid from 'ol/tilegrid/TileGrid';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import { getTopLeft } from 'ol/extent';
import { VectorTile } from 'ol/source';
import { MVT } from 'ol/format';
import { Feature } from 'ol';
import TileLayer from 'ol/layer/Tile';

export const createMapSource = (layerName: string, cached = window.location.search === '?gwc') => {
    return new TileWMS({
        url: `${baseURL}/geoserver/${cached ? 'gwc/service/' : ''}wms`,
        projection: 'EPSG:3857',
        hidpi: false,
        tileGrid: new TileGrid({
            extent: epsg3857TileInfo.extent.slice(0, 18), //
            resolutions: epsg3857TileInfo.resolutions.slice(0, 18),
            tileSize: 512
        }),
        attributions:
            layerName === 'maps:osm'
                ? '© <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors.'
                : undefined,
        params: { LAYERS: layerName, TILED: true, SRS: 'EPSG:3857' },
        serverType: 'geoserver'
    });
};

const vectorTileInfo = getTileInfo('EPSG:3857', 256);

export const createVectorMapSource = (layerName: string, cached = window.location.search === '?gwc') => {
    const workspace = layerName.split(':')[0];
    const tileGrid = new WMTSTileGrid({
        origin: getTopLeft(vectorTileInfo.extent),
        resolutions: vectorTileInfo.resolutions,
        matrixIds: vectorTileInfo.matrixIds,
        tileSize: 512
    });
    return new VectorTile({
        tileUrlFunction: function (tileCoord, pixelRatio, projection) {
            return `${baseURL}/geoserver/${workspace}/gwc/service/wmts?layer=${layerName}&style=&tilematrixset=WebMercatorQuadx2&Service=WMTS&Request=GetTile&Version=1.0.0&Format=application/vnd.mapbox-vector-tile&TileMatrix=${
                tileCoord[0] * 2 - 1
            }&TileCol=${tileCoord[1]}&TileRow=${tileCoord[2]}`;
        },
        tileLoadFunction: vectorTileLoadFunction,
        cacheSize: 0,
        format: new MVT({
            featureClass: Feature
        }),
        projection: 'EPSG:3857',
        tileGrid
    });
};

export const mapLayer = new TileLayer({
    source: undefined,
    maxZoom: 22,
    zIndex: 0,
    properties: { id: 'map-layer', overlay: true }
});

