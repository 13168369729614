import VectorLayer from 'ol/layer/Vector';
import { Fill, Stroke, Style } from 'ol/style';

const style = new Style({
    stroke: new Stroke({
        color: '#DE5D00',
        width: 1
    }),
    fill: new Fill({
        color: [222, 93, 0, 0.08]
    })
});

export const projectLayer = new VectorLayer({
    source: undefined,
    style,
    properties: { id: 'project_buffer', overlay: true },
    zIndex: 13
});
