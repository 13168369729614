import { gpsMarker } from 'components/icons/mapSymbols';
import VectorLayer from 'ol/layer/Vector';
import { Icon, Style } from 'ol/style';

export const markerLayer = new VectorLayer({
    properties: { id: 'marker_layer' },
    style: new Style({
        image: new Icon({
            src: gpsMarker,
            scale: 1
        })
    }),
    declutter: true,
    zIndex: 1000
});

