import { TextField } from '@mui/material';
import { CloseMark, LocationTarget } from 'components/icons';
import { markerLayer, Point } from 'helpers';
import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { coordsPopupActions, LocationSearchTab, mapActions, MapState, RootState, tabActions } from 'store';
import classes from './CoordFinderBody.module.scss';
import AddressSearchField from './addressSearchField/AddressSearchField';

const CoordFinderBody = () => {
    const dispatch = useDispatch();
    const { locationSearchTab } = useSelector((state: RootState) => state.tab);
    const { markerInfo } = useSelector((x: RootState) => x.map as MapState);
    const [lat, long] = useMemo(() => markerInfo.coordinateString.replace(/\s+/g, '').split(','), [markerInfo]);
    const isCoordValid = useMemo(() => {
        const latitude = parseFloat(lat);
        const longitude = parseFloat(long);

        const isValidNumber = (value: string) => /^[+-]?([0-9]*[.])?[0-9]+$/.test(value);
        const isWithinRange = (lat: number, long: number) => lat >= -90 && lat <= 90 && long >= -180 && long <= 180;

        if (!isValidNumber(lat) || !isValidNumber(long)) return false;
        if (!isWithinRange(latitude, longitude)) return false;

        return true;
    }, [long, lat]);

    const onChangeHandler = (value: string) => {
        if (value === '') handleClearCoord();
        dispatch(mapActions.setMarkerInfo({ coordinateString: value, type: locationSearchTab }));
    };

    const handleSearchByCoord = () => {
        if (!isCoordValid) return;
        const webMercatorCoord = transform([+long, +lat], 'EPSG:4326', 'EPSG:3857') as [number, number];
        const point: Point = {
            type: 'Point',
            coordinates: webMercatorCoord
        };
        const format = new GeoJSON();
        const geometry = format.readGeometry(point);
        const feature = new Feature(geometry);
        dispatch(
            mapActions.setMarkerInfo({
                coordinateString: `${lat}, ${long}`,
                type: locationSearchTab
            })
        );
        dispatch(coordsPopupActions.setCoordinateString(`${lat}, ${long}`));
        dispatch(mapActions.zoomOnPoint(point));
        markerLayer.setSource(
            new VectorSource({
                features: [feature]
            })
        );
    };

    const handleClearCoord = () => {
        dispatch(mapActions.setMarkerInfo({ coordinateString: '', type: locationSearchTab }));
        dispatch(coordsPopupActions.setCoordinateString(''));
        dispatch(mapActions.setMarkerInfo({ selectedOption: undefined }));
        markerLayer.setSource(null);
    };

    const handleSelectTab = (tab: LocationSearchTab) => dispatch(tabActions.setLocationSearchTab(tab));

    return (
        <>
            <hr />
            <div className={classes.coord}>
                <div className={classes.tabbarContainer}>
                    <button
                        className={`${classes.addressBtn} ${locationSearchTab === 'address' ? classes.selected : ''}`}
                        onClick={() => handleSelectTab(LocationSearchTab.Address)}
                    >
                        Indirizzo
                    </button>
                    <button
                        className={`${classes.coordBtn} ${locationSearchTab === 'coord' ? classes.selected : ''}`}
                        onClick={() => handleSelectTab(LocationSearchTab.Coord)}
                    >
                        Coordinate
                    </button>
                </div>
                <div className={classes.wrap}>
                    {locationSearchTab === 'address' ? (
                        <></>
                    ) : (
                        <>
                            <label htmlFor="coord">Coordinate</label>
                            <span>(lat, long)</span>
                        </>
                    )}
                </div>
                <div className={classes.wrap}>
                    {locationSearchTab === 'address' ? (
                        <AddressSearchField handleSearchByCoord={handleSearchByCoord} handleClearCoord={handleClearCoord} />
                    ) : (
                        <>
                            <TextField
                                value={markerInfo.type === 'coord' ? markerInfo.coordinateString : ''}
                                onChange={e => onChangeHandler(e.target.value)}
                                onKeyDown={e => e.key === 'Enter' && handleSearchByCoord()}
                                InputProps={{
                                    className: classes.inputBox,
                                    endAdornment:
                                        markerInfo.coordinateString !== '' && markerInfo.type === 'coord' ? (
                                            <div className={classes.closeIcon} onClick={handleClearCoord}>
                                                <CloseMark />
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                }}
                                id="coord"
                                placeholder="(es. 41.8856460, 12.4608743)"
                                sx={{ padding: '0 2px ' }}
                            />
                            <div
                                className={`${classes.button} ${
                                    isCoordValid && markerInfo.type === 'coord' ? classes.enabled : ''
                                }`}
                                onClick={() => {
                                    handleSearchByCoord();
                                    dispatch(mapActions.setMarkerInfo({ selectedOption: undefined }));
                                }}
                            >
                                <LocationTarget fill={isCoordValid && markerInfo.type === 'coord' ? '#FFFFFF' : '#ACB9BD'} />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default CoordFinderBody;

