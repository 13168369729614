import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import { baseURL } from 'helpers';

export const statsApi = createApi({
    reducerPath: 'statsApi',
    keepUnusedDataFor: 3600,
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseURL}/api/v1/`
    }),
    endpoints: builder => ({
        getRegionStats: builder.query<GeoJSONFeatureCollection, void>({
            query: () => `stats/regions`,
            transformResponse: baseQueryReturnValue => (baseQueryReturnValue as any).regions
        })
    })
});

export const { useGetRegionStatsQuery, useLazyGetRegionStatsQuery } = statsApi;

