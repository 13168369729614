import VectorLayer from 'ol/layer/Vector';
import { styles } from './styles';

export const uiRoeWebLayer = new VectorLayer({
    source: undefined,
    style: styles['LineString'],
    properties: { id: 'ui_roe_web_layer', overlay: true },
    zIndex: 13,
    minZoom: 17.5
});
