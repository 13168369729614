import { configureStore } from '@reduxjs/toolkit';
import { mapReducer } from './map.slice';
import { drawReducer } from './draw.slice';
import { searchReducer } from './search.slice';
import { popupReducer } from './popup.slice';
import { searchApi } from './rtk/search.service';
import { statsApi } from './rtk/stats.service';
import { featuresApi } from './rtk/features.service';
import { mapboxApi } from './rtk/mapbox.service';
import { downloadProjectReducer } from './downloadProject.slice';
import { resetPasswordReducer } from './resetPassword.slice';
import { coordsPopupReducer } from './coordsPopup.slice';
import { tabReducer } from './tab.slice';

export * from './map.slice';
export * from './draw.slice';
export * from './search.slice';
export * from './popup.slice';
export * from './rtk/search.service';
export * from './rtk/stats.service';
export * from './rtk/features.service';
export * from './rtk/mapbox.service';
export * from './downloadProject.slice';
export * from './resetPassword.slice';
export * from './coordsPopup.slice';
export * from './tab.slice';

export const store = configureStore({
    reducer: {
        map: mapReducer,
        search: searchReducer,
        draw: drawReducer,
        popup: popupReducer,
        downloadProject: downloadProjectReducer,
        resetPassword: resetPasswordReducer,
        coordsPopup: coordsPopupReducer,
        tab: tabReducer,
        [searchApi.reducerPath]: searchApi.reducer,
        [statsApi.reducerPath]: statsApi.reducer,
        [featuresApi.reducerPath]: featuresApi.reducer,
        [mapboxApi.reducerPath]: mapboxApi.reducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: false })
            .concat(searchApi.middleware)
            .concat(statsApi.middleware)
            .concat(featuresApi.middleware)
            .concat(mapboxApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;

