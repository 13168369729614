import { FC } from 'react';

type LocationTargetProps = {
    fill?: string;
};

export const LocationTarget: FC<LocationTargetProps> = ({ fill = '#0050DE' }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.6389 12C19.6389 16.2188 16.2188 19.6389 12 19.6389M19.6389 12C19.6389 7.78116 16.2188 4.36111 12 4.36111M19.6389 12H24M12 19.6389C7.78116 19.6389 4.36111 16.2188 4.36111 12M12 19.6389V24M4.36111 12C4.36111 7.78116 7.78116 4.36111 12 4.36111M4.36111 12H0M12 4.36111V0M15.3333 12C15.3333 13.8409 13.8409 15.3333 12 15.3333C10.1591 15.3333 8.66667 13.8409 8.66667 12C8.66667 10.1591 10.1591 8.66667 12 8.66667C13.8409 8.66667 15.3333 10.1591 15.3333 12Z"
                stroke={fill}
                strokeWidth="1.5"
            />
        </svg>
    );
};

