import Feature, { FeatureLike } from 'ol/Feature';
import RenderFeature from 'ol/render/Feature';
import { Fill, Icon, Stroke, Style } from 'ol/style.js';
import { hoveredFeatureId, hoveredLayer } from './../constants';
export type FeatureType =
    | 'Polygon'
    | 'MultiPolygon'
    | 'LineString'
    | 'MultiLineString'
    | 'Point'
    | 'MultiPoint'
    | 'GeometryCollection';
export type Stylefunction = Style | ((feature: Feature | RenderFeature, resolution: any) => Style);

export const styles: Record<FeatureType, Style | Style[] | Stylefunction> = {
    GeometryCollection: new Style(),
    MultiPolygon: new Style(),
    Polygon: new Style(),
    MultiLineString: new Style(),
    LineString: new Style(),
    MultiPoint: new Style(),
    Point: new Style()
};

const fwa = [216, 144, 0, 1];
const purple = [93, 0, 186, 1];
const blue = [72, 173, 211, 1];
const white = [255, 255, 255, 1];

const pointStyle = (feature: FeatureLike) => {
    const props = feature.getProperties();
    const layerStyleMap: { [key: string]: string } = {
        diretto_pac_pal: 'img/diretto/pac_pal.png',
        diretto_ui: 'img/diretto/ui.png',
        diretto_roe: 'img/diretto/roe.png',
        diretto_pozzetti: 'img/diretto/pozzetti.png',
        diretto_pcn: 'img/diretto/pcn.png',
        diretto_cno: 'img/diretto/cno.png',
        diretto_nodi_operatore: 'img/diretto/nodo_operatore.png',
        diretto_giunti: 'img/diretto/giunti.png',
        concessione_pac_pal: 'img/concessione/pac_pal.png',
        concessione_ui: 'img/concessione/ui.png',
        concessione_roe: 'img/concessione/roe.png',
        concessione_pozzetti: 'img/concessione/pozzetti.png',
        concessione_pcn: 'img/concessione/pcn.png',
        concessione_cno: 'img/concessione/cno.png',
        concessione_nodi_operatore: 'img/concessione/nodo_operatore.png',
        fwa_srb: 'img/fwa/srb.png',
        fwa_ui: 'img/fwa/ui.png'
    };

    let src = layerStyleMap[props.layer];

    const isUI = props.layer.endsWith('ui');

    return new Style({
        image: new Icon({
            src,
            declutterMode: 'none',
            scale: isUI ? 1.125 : 0.75
        })
    });
};

const lineStyle = (feature: FeatureLike) => {
    const props = feature.getProperties();
    let color = white;
    let lineDash: number[] | undefined;
    let lineDashOffset: number | undefined;
    let width = 3;
    if (props.idproprietario !== undefined && props.idproprietario !== 1) {
        lineDash = [10, 10];
        lineDashOffset = 5;
    }
    if (props.ui_roe_web) {
        lineDash = [4, 5];
        lineDashOffset = 5;
        width = 2;
    }
    switch (props.layer) {
        case 'diretto_tratte':
        case 'diretto_ui_roe_web':
            color = blue;
            break;
        case 'concessione_tratte':
        case 'concessione_ui_roe_web':
            color = purple;
            break;
    }

    return new Style({
        stroke: new Stroke({
            color,
            width,
            lineDash,
            lineDashOffset
        })
    });
};

const areaStyle = (feature: FeatureLike) => {
    const props = feature.getProperties();
    switch (props.layer) {
        case 'istat_regioni':
            if (props.layer === hoveredLayer.value && props.id === hoveredFeatureId.value)
                return new Style({
                    stroke: new Stroke({
                        color: [255, 157, 10, 0.5],
                        width: 1.75
                    }),
                    fill: new Fill({
                        color: '#FF9D0A33'
                    })
                });
            else
                return new Style({
                    stroke: new Stroke({
                        color: [255, 157, 10, 0.5],
                        width: 1.75
                    }),
                    fill: new Fill({
                        color: '#00000000'
                    })
                });
        case 'istat_province':
            if (props.layer === hoveredLayer.value && props.id === hoveredFeatureId.value)
                return new Style({
                    stroke: new Stroke({
                        color: '#FF9D0A33',
                        width: 1.5
                    }),
                    fill: new Fill({
                        color: '#FF9D0A33'
                    })
                });
            else
                return new Style({
                    stroke: new Stroke({
                        color: '#FF9D0A33',
                        width: 1.5
                    }),
                    fill: new Fill({
                        color: '#00000000'
                    })
                });
        case 'istat_comuni':
            if (props.layer === hoveredLayer.value && props.id === hoveredFeatureId.value)
                return new Style({
                    stroke: new Stroke({
                        color: '#FF9D0A33',
                        width: 1
                    }),
                    fill: new Fill({
                        color: '#FF9D0A33'
                    })
                });
            else
                return new Style({
                    stroke: new Stroke({
                        color: '#FF9D0A33',
                        width: 1
                    }),
                    fill: new Fill({
                        color: '#00000000'
                    })
                });
        case 'fwa_area_copertura':
            return new Style({
                stroke: new Stroke({
                    color: fwa,
                    width: 0.75
                }),
                fill: new Fill({
                    color: [245, 193, 10, 0.1]
                })
            });
        default:
            return new Style({
                stroke: new Stroke({
                    color: white,
                    width: 3
                })
            });
    }
};

styles['MultiLineString'] = lineStyle;
styles['LineString'] = lineStyle;
styles['MultiPolygon'] = areaStyle;
styles['Polygon'] = areaStyle;
styles['Point'] = pointStyle;
