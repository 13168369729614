import { FC } from 'react';

export const CloseMark: FC = () => {
    return (
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.16699 0.666504L12.8337 12.3332M12.8337 0.666504L1.16699 12.3332"
                stroke="#ACB9BD"
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
        </svg>
    );
};

