import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from 'helpers';

type MapboxTokenStyle = { token?: string; style?: undefined };

export const mapboxApi = createApi({
    reducerPath: 'mapboxApi',
    keepUnusedDataFor: 3600,
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseURL}/api/v1/`
    }),
    endpoints: builder => ({
        getTokenAndStyle: builder.query<MapboxTokenStyle, void>({
            query: () => `mapbox`,
            transformResponse: baseQueryReturnValue => baseQueryReturnValue as MapboxTokenStyle
        })
    })
});

export const { useGetTokenAndStyleQuery, useLazyGetTokenAndStyleQuery } = mapboxApi;

