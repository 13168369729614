import GeoJSON, { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Style, Text } from 'ol/style';
import { hoveredFeatureId, hoveredLayer, municipalitiesMaxZoom, regionsMaxZoom, statsMaxZoom } from 'helpers';

export const createProvinceLabelLayer = (collection: GeoJSONFeatureCollection) => {
    const features = new GeoJSON().readFeatures(collection);
    const source = new VectorSource({ features });
    const provinceLabelLayer = new VectorLayer({
        source,
        minZoom: regionsMaxZoom,
        maxZoom: municipalitiesMaxZoom,
        declutter: true,
        zIndex: 10001,
        style: feature => {
            const nome = feature.get('nome');
            if (hoveredLayer.value === 'istat_province' && feature.getProperties().id === hoveredFeatureId.value)
                return new Style({
                    text: new Text({
                        text: nome,
                        fill: new Fill({ color: 'rgba(255, 190, 87, 1)' }),
                        backgroundFill: new Fill({
                            color: 'rgba(179, 104, 0, 1)'
                        }),
                        padding: [0, 10, -3, 10],
                        font: 'bold 24px Titillium Web',
                        textBaseline: 'middle'
                    })
                });
        },
        properties: { id: 'province-label-layer', overlay: true }
    });
    return provinceLabelLayer;
};
