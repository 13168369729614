import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import { baseURL, Point } from 'helpers';

export const featuresApi = createApi({
    reducerPath: 'featuresApi',
    keepUnusedDataFor: 3600,
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseURL}/api/v1/`
    }),
    endpoints: builder => ({
        getProvinceCollection: builder.query<GeoJSONFeatureCollection, void>({
            query: () => `features/provinces`,
            transformResponse: baseQueryReturnValue => (baseQueryReturnValue as any).provinces
        }),
        getUIFromROE: builder.query<Point[], { id: string; dbSource: string }>({
            query: ({ id, dbSource }) => `features/uis?idroe=${id}&dbsource=${dbSource.split('_')[0]}`,
            transformResponse: baseQueryReturnValue => (baseQueryReturnValue as any).uis
        }),
        getROEFromUI: builder.query<Point, { id: string; dbSource: string }>({
            query: ({ id, dbSource }) => `features/roe?idui=${id}&dbsource=${dbSource.split('_')[0]}`,
            transformResponse: baseQueryReturnValue => (baseQueryReturnValue as any).roe
        }),
        getMunicipalitiesCollection: builder.query<GeoJSONFeatureCollection, void>({
            query: () => `features/municipalities`,
            transformResponse: baseQueryReturnValue => (baseQueryReturnValue as any).municipalities
        })
    })
});

export const {
    useGetProvinceCollectionQuery,
    useLazyGetProvinceCollectionQuery,
    useLazyGetUIFromROEQuery,
    useLazyGetROEFromUIQuery,
    useGetMunicipalitiesCollectionQuery,
    useLazyGetMunicipalitiesCollectionQuery
} = featuresApi;
