import { Popper } from '@mui/material';
import { CloseMark, GpsMarkerOnMap } from 'components/icons';
import { map, modelTitles, Point, selectionLayer, SelectionUtils } from 'helpers';
import { Feature } from 'ol';
import { Geometry, LineString } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { popupActions, RootState, useLazyGetROEFromUIQuery, useLazyGetUIFromROEQuery } from 'store';
import colors from 'styles/modules/colors.module.scss';
import classes from './InfoPopper.module.scss';

export const InfoPopper: FC = () => {
    const { open, title, model, info, properties, coordinates } = useSelector((root: RootState) => root.popup);
    const infoRef = useRef<HTMLDivElement>(null);
    const [arrowRef, setArrowRef] = useState(null);
    const dispatch = useDispatch();
    const [getUIFromROE] = useLazyGetUIFromROEQuery();
    const [getROEFromUI] = useLazyGetROEFromUIQuery();

    const isROE = useMemo(() => SelectionUtils.featureLookupTable._roe === title, [title]);
    const isUI = useMemo(() => SelectionUtils.featureLookupTable._ui === title, [title]);

    const onROEButtonClick = useCallback(async () => {
        const res = await getUIFromROE({ id: properties.id!, dbSource: properties.layer! });
        if (!res?.data) return;
        updateUiRoeWebLayer(res.data);
    }, [properties]);

    const updateUiRoeWebLayer = useCallback(
        (poi: Point[] | null = null) => {
            const layers = map.getAllLayers();
            const uiRoeWebLayer = layers.find(layer => layer.getProperties().id === 'ui_roe_web_layer') as VectorLayer<
                VectorSource<Geometry>
            >;
            if (!poi || !coordinates) uiRoeWebLayer.setSource(null);
            else {
                uiRoeWebLayer.setSource(
                    new VectorSource({
                        features: poi.map(point => {
                            const lineString = new LineString([point.coordinates, coordinates]);
                            const feature = new Feature(lineString);
                            feature.setProperties({ ui_roe_web: true, layer: `${properties.layer?.split('_')[0]}_ui_roe_web` });
                            return feature;
                        })
                    })
                );
            }
            uiRoeWebLayer.changed();
        },
        [map, coordinates]
    );

    const onPopupClose = useCallback(() => {
        dispatch(popupActions.setOpen(false));
        selectionLayer.setSource(null);
    }, []);

    useEffect(() => {
        (async () => {
            if (!isUI) return;
            const res = await getROEFromUI({ id: properties.id!, dbSource: properties.layer! });
            if (!res?.data) return;
            updateUiRoeWebLayer([res.data]);
        })();
    }, [properties, isUI]);

    useEffect(() => {
        if (isROE || isUI) return;
        const layers = map.getAllLayers();
        const uiRoeWebLayer = layers.find(layer => layer.getProperties().id === 'ui_roe_web_layer') as VectorLayer<
            VectorSource<Geometry>
        >;
        uiRoeWebLayer?.setSource(null);
        uiRoeWebLayer?.changed();
    }, [info]);

    return (
        <>
            <div id="info" ref={infoRef} className={classes.anchor} />
            <Popper
                open={open}
                key={`${open}`}
                anchorEl={infoRef.current}
                className={classes.popper}
                placement="auto"
                disablePortal={false}
                modifiers={[
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef
                        }
                    }
                ]}
            >
                <div>
                    <div ref={setArrowRef as any} className={classes.arrow} />
                    <div className={classes.paper}>
                        <div className={classes.main}>
                            <div className={classes.body}>
                                <b>{title}</b>
                                <b style={{ color: colors[model] }} className={classes.small}>
                                    {`Modello ${modelTitles[model as keyof typeof modelTitles]}`}
                                </b>
                                <div className={`${classes.small} ${classes.props}`}>
                                    {Object.entries(info).map(([key, value]) =>
                                        value && value.toLocaleLowerCase() !== 'na' ? (
                                            <div key={key}>
                                                <span className={classes.key}>{key}:</span>&nbsp;
                                                <span className={classes.value}>{value}</span>
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    )}
                                </div>
                            </div>
                            <button className={classes.closeButton} onClick={onPopupClose}>
                                <CloseMark />
                            </button>
                        </div>
                        {isROE && (
                            <button title="Acquire" type="button" onClick={onROEButtonClick} className={classes.buttons}>
                                <b>Mostra UI</b>
                                <GpsMarkerOnMap />
                            </button>
                        )}
                    </div>
                </div>
            </Popper>
        </>
    );
};
