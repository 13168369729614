import { vectorTileLoadFunction } from 'helpers/functions';
import { MVT } from 'ol/format';
import VectorTileLayer from 'ol/layer/VectorTile';
import { VectorTile } from 'ol/source';
import { Stroke, Style } from 'ol/style';

const italySource = new VectorTile({
    tileLoadFunction: vectorTileLoadFunction,
    format: new MVT(),
    maxZoom: 11,
    url: `api/v1/tiles/italy/{z}/{x}/{y}.pbf`
});

export const italyLayer = new VectorTileLayer({
    source: italySource,
    renderMode: 'vector',
    zIndex: 5000,
    maxZoom: 22,
    properties: { id: 'italy', overlay: true },
    style: new Style({
        stroke: new Stroke({
            color: [255, 157, 10, 1],
            width: 3
        })
    })
});

