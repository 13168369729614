import { FC } from 'react';
import { GpsMarker } from 'components/icons';
import classes from './CoordsPopup.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const CoordsPopup: FC = () => {
    const { coordinateString } = useSelector((root: RootState) => root.coordsPopup);

    if (!coordinateString) return <></>;

    return (
        <div className={classes.container}>
            <span className={classes.icon}>
                <GpsMarker />
            </span>
            <span className={classes.coords}>{coordinateString}</span>
        </div>
    );
};

export default CoordsPopup;

