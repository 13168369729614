import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum LocationSearchTab {
    Address = 'address',
    Coord = 'coord'
}

interface State {
    locationSearchTab: LocationSearchTab;
}

export interface TabState extends State {}

const name = 'tabState';
const initialState: State = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const tabActions = { ...slice.actions };
export const tabReducer = slice.reducer;

function createInitialState() {
    return {
        locationSearchTab: 'address'
    } as State;
}

function createReducers() {
    return { setLocationSearchTab };

    function setLocationSearchTab(state: TabState, action: PayloadAction<LocationSearchTab>) {
        state.locationSearchTab = action.payload;
    }
}

