import { MapboxVectorLayer } from 'ol-mapbox-style';

export const getMapboxLayer = (accessToken: string, styleUrl: string) => {
    const mapboxLayer = new MapboxVectorLayer({
        properties: { id: 'mapbox' },
        accessToken,
        styleUrl,
        declutter: true,
        zIndex: 0
    });
    mapboxLayer.getSource()?.setAttributions(`© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> 
        © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>
        <strong><a href="https://labs.mapbox.com/contribute/" target="_blank">Improve this map</a></strong>`);
    return mapboxLayer;
};
