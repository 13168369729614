import {
    RootState,
    mapActions,
    searchActions,
    useGetProjectsQuery,
    useGetProvincesQuery,
    useGetMunicipalitiesQuery,
    useGetRegionsQuery,
    useGetSrbsQuery,
    useLazyGetProjectBoundsQuery
} from 'store';
import { AreaOption, map, Polygon, ProjectOption, SrbOption } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import { Geometry } from 'ol/geom';
import * as turf from '@turf/turf';

export const useSearchOptions = () => {
    const dispatch = useDispatch();
    const { regionId, provinceId, projectId, municipalityId, srbId } = useSelector((x: RootState) => x.search);
    const { data: regions, isFetching: regionsLoading } = useGetRegionsQuery();
    const { data: provinces, isFetching: provincesLoading } = useGetProvincesQuery(regionId || 0);
    const { data: municipalities, isFetching: municipalitiesLoading, currentData } = useGetMunicipalitiesQuery(provinceId || 0);
    const { data: projects, isFetching: projectsLoading } = useGetProjectsQuery(municipalityId || 0, { skip: !municipalityId });
    const [getProjectBounds] = useLazyGetProjectBoundsQuery();

    const regionOptions: AreaOption[] = useMemo(
        () =>
            regions
                ? regions.map(region => {
                      return { id: region.id, label: region.nome, bbox: region.bbox };
                  })
                : [],
        [regions]
    );
    const regionOption: AreaOption | undefined = useMemo(
        () => regionOptions.find(option => option.id === regionId) ?? { id: -1, label: '', bbox: undefined as any },
        [regionOptions, regionId]
    );

    const provinceOptions: AreaOption[] = useMemo(
        () =>
            provinces
                ? provinces.map(province => {
                      return { id: province.id, label: province.nome, bbox: province.bbox };
                  })
                : [],
        [provinces, regionId]
    );
    const provinceOption: AreaOption | undefined = useMemo(
        () => provinceOptions.find(option => option.id === provinceId) ?? { id: -1, label: '', bbox: undefined as any },
        [provinceOptions, provinceId]
    );

    const municipalityOptions: AreaOption[] = useMemo(
        () =>
            municipalities
                ? municipalities.map(municipality => ({ id: municipality.id, label: municipality.nome, bbox: municipality.bbox }))
                : [],
        [municipalities, provinceId]
    );
    const municipalityOption: AreaOption | undefined = useMemo(
        () =>
            municipalityOptions.find(option => option.id === municipalityId) ?? {
                id: -1,
                label: '',
                bbox: undefined as any
            },
        [municipalityOptions, municipalityId]
    );

    const projectOptions: ProjectOption[] = useMemo(
        () =>
            municipalityId && projects
                ? projects.map(project => {
                      const labelSuffix = project.fwa ? '(Conc. FWA)' : project.diretto ? '(Diretto)' : '(Conc. FTTH)';
                      return {
                          id: `${project.nome}${project.fwa}${project.diretto}`,
                          project: project.project,
                          label: `${project.nome} ${labelSuffix}`,
                          fwa: project.fwa,
                          bbox: project.bbox,
                          type: project.fwa ? 'fwa' : project.diretto ? 'diretto' : 'concessione'
                      };
                  })
                : [],
        [projects, municipalityId]
    );
    const projectOption: ProjectOption | undefined = useMemo(
        () =>
            projectOptions.find(option => option.id === projectId) ?? {
                id: '',
                project: '',
                label: '',
                bbox: undefined as any,
                fwa: false
            },
        [projectOptions, projectId]
    );

    const { data: srbs, isFetching: srbsLoading } = useGetSrbsQuery(projectId ?? '', { skip: !projectId || !projectOption?.fwa });
    const srbOptions: SrbOption[] = useMemo(() => {
        if (!projectId || !projectOption?.fwa) return [];
        return srbs
            ? srbs.map(srb => {
                  return { id: srb.id, label: srb.codice, coords: srb.coords };
              })
            : [];
    }, [projectId, projectOption, srbs]);
    const srbOption: SrbOption | undefined = useMemo(
        () => srbOptions.find(option => option.label === srbId) ?? { id: -1, label: '', coords: undefined as any },
        [srbOptions, srbId]
    );

    const onRegionChangeHandler = (value: AreaOption) => {
        if (value.bbox) dispatch(mapActions.zoomOnPolygon(value.bbox));
        dispatch(searchActions.setRegionId(value.id));
        updateProjectLayer();
    };

    useEffect(() => {
        if (provinces && regionId) {
            if (!provinces.find(province => province.id === provinceId)) {
                dispatch(searchActions.setProvinceId(undefined));
                dispatch(searchActions.setMunicipalityId(undefined));
                dispatch(searchActions.setProjectId(undefined));
                dispatch(searchActions.setSrbId(undefined));
            }
        }
    }, [provinces]);

    const onProvinceChangeHandler = (value: AreaOption) => {
        if (value.bbox) dispatch(mapActions.zoomOnPolygon(value.bbox));
        dispatch(searchActions.setProvinceId(value.id));
        updateProjectLayer();
    };

    useEffect(() => {
        if (municipalities && provinceId) {
            if (!municipalities.find(municipality => municipality.id === municipalityId)) {
                dispatch(searchActions.setMunicipalityId(undefined));
                dispatch(searchActions.setProjectId(undefined));
                dispatch(searchActions.setSrbId(undefined));
            }
        }
    }, [municipalities]);

    const onMunicipalityChangeHandler = (value: AreaOption) => {
        if (value.bbox) dispatch(mapActions.zoomOnPolygon(value.bbox));
        dispatch(searchActions.setMunicipalityId(value.id));
        dispatch(searchActions.setProjectId(undefined));
        dispatch(searchActions.setSrbId(undefined));
        updateProjectLayer();
    };

    const onProjectChangeHandler = async (value: ProjectOption) => {
        let source = null;
        let bbox = value.bbox;
        if (['concessione', 'diretto'].includes(value.type!)) {
            const res = await getProjectBounds({ project: value.project, dbSource: value.type! });
            if (res?.data) {
                const transformedFeature = new GeoJSON().readFeature(res.data).getGeometry()?.transform('EPSG:4326', 'EPSG:3857');
                const feature = new Feature(transformedFeature);
                source = new VectorSource({ features: [feature] });
                const geometry = (feature.getGeometry() as any).getCoordinates();
                const polygon =
                    typeof geometry[0][0][0] === 'number'
                        ? turf.polygon(geometry)
                        : turf.featureCollection(geometry.map((g: any) => turf.polygon(g)));

                bbox = turf.bboxPolygon(turf.bbox(polygon)).geometry as Polygon;
            }
        }
        if (bbox) dispatch(mapActions.zoomOnPolygon(bbox));
        updateProjectLayer(source);
        dispatch(searchActions.setProjectId(value.id));
        dispatch(searchActions.setSrbId(undefined));
    };

    const updateProjectLayer = useCallback(
        (source: VectorSource | null = null) => {
            const layers = map.getAllLayers();
            const projectLayer = layers.find(layer => layer.getProperties().id === 'project_buffer') as VectorLayer<
                VectorSource<Geometry>
            >;
            projectLayer.setSource(source);
            projectLayer.changed();
        },
        [map]
    );

    const onSrbChangeHandler = (value: SrbOption) => {
        if (value.coords) dispatch(mapActions.zoomOnPoint(value.coords));
        dispatch(searchActions.setSrbId(value.label));
    };

    return {
        regionOptions,
        regionOption,
        regionsLoading,
        provinceOptions,
        provinceOption,
        provincesLoading,
        municipalityOptions,
        municipalityOption,
        municipalitiesLoading,
        projectOptions,
        projectOption,
        projectsLoading,
        srbOptions,
        srbOption,
        srbsLoading,
        onRegionChangeHandler,
        onProvinceChangeHandler,
        onMunicipalityChangeHandler,
        onProjectChangeHandler,
        onSrbChangeHandler
    };
};
