import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AddressData, Area, Polygon, Project, Srb, baseURL, sortByStringProp } from 'helpers';

export const searchApi = createApi({
    reducerPath: 'searchApi',
    keepUnusedDataFor: 3600,
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseURL}/api/v1/`
    }),
    endpoints: builder => ({
        getRegions: builder.query<Area[], void>({
            query: () => `search/regions`,
            transformResponse: baseQueryReturnValue =>
                (baseQueryReturnValue as any).regions.sort((a: any, b: any) => sortByStringProp(a, b, 'nome'))
        }),
        getProvinces: builder.query<Area[], number>({
            query: regionId => `search/provinces?region=${regionId}`,
            transformResponse: baseQueryReturnValue =>
                (baseQueryReturnValue as any).provinces.sort((a: any, b: any) => sortByStringProp(a, b, 'nome'))
        }),
        getMunicipalities: builder.query<Area[], number>({
            query: provinceId => `search/municipalities?province=${provinceId}`,
            transformResponse: baseQueryReturnValue =>
                (baseQueryReturnValue as any).municipalities.sort((a: any, b: any) => sortByStringProp(a, b, 'nome'))
        }),
        getProjects: builder.query<Project[], number>({
            query: municipalityId => `search/projects?municipality=${municipalityId}`,
            transformResponse: baseQueryReturnValue => (baseQueryReturnValue as any).projects
        }),
        getSrbs: builder.query<Srb[], string>({
            query: projectId => `search/srbs?project=${projectId.substring(0, projectId.length - 13)}`,
            transformResponse: baseQueryReturnValue => (baseQueryReturnValue as any).srbs
        }),
        getProjectBounds: builder.query<Polygon, { project: string; dbSource: string }>({
            query: ({ project, dbSource }) => `search/project-bounds?project=${project}&dbSource=${dbSource}`,
            transformResponse: baseQueryReturnValue => (baseQueryReturnValue as any).buffered_bounds
        }),
        getAddresses: builder.query<AddressData, string>({
            query: addrString => `search/address?address=${addrString}`
        })
    })
});

export const {
    useGetRegionsQuery,
    useLazyGetRegionsQuery,
    useGetProvincesQuery,
    useLazyGetProvincesQuery,
    useGetMunicipalitiesQuery,
    useLazyGetMunicipalitiesQuery,
    useGetProjectsQuery,
    useLazyGetProjectsQuery,
    useGetSrbsQuery,
    useLazyGetSrbsQuery,
    useLazyGetProjectBoundsQuery,
    useGetAddressesQuery,
    useLazyGetAddressesQuery
} = searchApi;
