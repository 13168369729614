import { modelTitles } from 'helpers';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LayerGroupInfo, MapState, RootState } from 'store';
import colors from 'styles/modules/colors.module.scss';
import { LayerSelect, LayerSelectProps } from '../layerTool/layerSelect/LayerSelect';
import ProjectFinderBody from '../projectFinder/projectFinderBody/ProjectFinderBody';
import TileSelector from '../tileSelector/TileSelector';
import classes from './ToolsPageMobile.module.scss';
import DownloadProjectBody from '../downloadProject/downloadProjectBody/DownloadProjectBody';
import { LayerInfo } from '../layerInfo/LayerInfo';
import CoordFinderBody from '../coordFinder/coordFinderBody/CoordFinderBody';

type ToolsPageMobileProps = FC<{
    tool: string;
}>;

const ToolsPageMobile: ToolsPageMobileProps = ({ tool }) => {
    const { layerGroupInfo } = useSelector((x: RootState) => x.map as MapState);
    const layerGroups: LayerSelectProps[] = useMemo(() => {
        const groupInfo = layerGroupInfo as LayerGroupInfo[];
        const LAYER_GROUPS: Omit<LayerSelectProps, 'visible' | 'toggles'>[] = [
            {
                id: 'diretto',
                title: modelTitles['diretto'],
                color: colors.diretto,
                tooltip: 'Il modello diretto è lorem ipsum dolor sit amet'
            },
            {
                id: 'concessione',
                title: modelTitles['concessione'],
                color: colors.concessione,
                tooltip: 'Il modello concessione è lorem ipsum dolor sit amet'
            },
            {
                id: 'fwa',
                title: modelTitles['fwa'],
                color: colors.fwa,
                tooltip: 'FWA lorem ipsum sit dolor amet'
            }
        ];
        return LAYER_GROUPS.filter(lg => groupInfo.map(gi => gi.id).includes(lg.id)).map(layer => {
            const info = groupInfo.find(gi => gi.id === layer.id)!;
            return {
                id: layer.id,
                title: layer.title,
                visible: info.visible,
                color: layer.color,
                toggles: info.layers.map(l => ({ id: l.name, label: l.title, visible: l.visible })),
                tooltip: layer.tooltip
            };
        });
    }, [layerGroupInfo]);

    return (
        <div className={classes.toolsPageMobile}>
            {tool === 'search' && (
                <div className={classes.toolWrapper}>
                    <ProjectFinderBody />
                </div>
            )}
            {tool === 'coord' && (
                <div className={classes.toolWrapper}>
                    <CoordFinderBody />
                </div>
            )}
            {tool === 'layer' && (
                <div className={classes.toolWrapper}>
                    <div className={classes.layerSelectWrapper}>
                        {layerGroups.map(layer => (
                            <LayerSelect key={layer.id} {...layer} />
                        ))}
                    </div>
                </div>
            )}
            {tool === 'download' && (
                <div className={classes.toolWrapper}>
                    <DownloadProjectBody />
                </div>
            )}
            {tool === 'mapStyle' && (
                <div className={classes.toolWrapper}>
                    <TileSelector isMobile />
                </div>
            )}
            {tool === 'legend' && (
                <div className={classes.toolWrapper}>
                    <LayerInfo />
                </div>
            )}
        </div>
    );
};

export default ToolsPageMobile;

