import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type CheckboxState = Record<string, boolean>;

interface State {
    regionId?: number;
    isSelectedAreaChecked: boolean;
    projectCheckbox: CheckboxState;
    downloading: boolean;
    error?: string;
    showErrorModal: boolean;
    enableDownload: boolean;
    fileCheckbox: CheckboxState;
    isValid: boolean;
    polygonData: any;
}

const name = 'downloadProjectState';
const initialState: State = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const downloadProjectActions = { ...slice.actions };
export const downloadProjectReducer = slice.reducer;

function createInitialState() {
    return {
        regionId: undefined,
        isSelectedAreaChecked: false,
        projectCheckbox: {
            diretto: false,
            concessione: false,
            fwa: false
        },
        downloading: false,
        error: undefined,
        showErrorModal: false,
        enableDownload: false,
        fileCheckbox: {
            csv: false,
            shape: false
        },
        isValid: false,
        polygonData: {}
    } as State;
}

function createReducers() {
    return {
        setState
    };
    function setState(state: State, action: PayloadAction<{ key: keyof State; value: any }>) {
        state[action.payload.key] = action.payload.value;
    }
}
